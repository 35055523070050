export const projects = [
  {
    id: "course-planner",
    img: "course-planner.png",
    path: "/course-planner",
    name: "College Course Planner",
    languages: "React, Tailwind, Node.js",
    description: "College Course Planner",
    date: "01-2025",
    info: (
      <>
        <p>
          CARPI is a full-stack course planning platform designed to provide RPI
          students with an intuitive and efficient way to structure their
          four-year academic journey. The frontend, built with{" "}
          <span class="font-bold">React</span> and styled using{" "}
          <span class="font-bold">Tailwind</span> CSS, features a responsive and
          interactive UI optimized for both desktop and mobile experiences.
          Smooth animations and a well-structured component architecture enhance
          usability, while optimized state management ensures real-time updates
          for seamless course selection and schedule adjustments. The frontend
          integrates with a <span class="font-bold">FastAPI</span> backend,
          utilizing RESTful API endpoints for efficient data retrieval and
          persistence in a SQL database. With a strong emphasis on frontend
          performance, user experience, and maintainability, CARPI delivers a
          seamless and visually engaging academic planning tool for students.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/Project-CARPI/CARPI-Site">here</a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "stockai",
    img: "stockai.png",
    path: "/stockai",
    name: "AI-powered Stock Predictor",
    languages: "Python, Tensorflow, Pandas",
    description: "AI-powered Stock Predictor",
    date: "01-2025",
    info: (
      <>
        <p>
          This project leverages advanced{" "}
          <span class="font-bold">machine learning</span> techniques to predict
          stock price movements with a focus on modularity and scalability.
          Utilizing <span class="font-bold">object-oriented programming</span>{" "}
          (OOP), I designed reusable classes to streamline an{" "}
          <span class="font-bold">ETL</span> pipeline for preprocessing
          financial and sentiment data. The pipeline incorporates technical
          indicators, historical market data, and real-time sentiment analysis
          from news sources to create robust feature sets. A deep learning
          model, based on <span class="font-bold">LSTMs</span>, processes
          time-series data to forecast stock trends, achieving improved accuracy
          through hyperparameter optimization and feature engineering. This
          project demonstrates proficiency in OOP, data engineering, and{" "}
          <span class="font-bold">AI model development</span>.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/zhangj23/stock-ai">here</a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "cilium",
    img: "cilium.png",
    path: "/cilium",
    name: "Open Source Testing",
    languages: "Cilium, Kubernetes, Azure",
    description: "Cilium Open Source Network Testing",
    date: "12-2024",
    info: (
      <>
        <p>
          In this project, I contributed to developing an enterprise-grade
          <span class="font-bold">testing suite</span> for{" "}
          <span class="font-bold">Cilium</span>, a powerful eBPF-based
          networking, observability, and security solution. My primary focus was
          on testing <span class="font-bold">pod-to-pod</span> and{" "}
          <span class="font-bold">host-to-pod</span>
          communication to ensure robust connectivity and reliability.
          Throughout the project, I gained in-depth knowledge of{" "}
          <span class="font-bold">Kubernetes</span>
          architecture, cluster management, and cloud hosting solutions on
          <span class="font-bold">Azure</span>. This experience solidified my
          skills in cloud-native technologies and scalable network
          infrastructure.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/smoleyxd/RCOS-Cilium/tree/justin">
              here
            </a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "sudoku",
    img: "sudoku.jpg",
    path: "/sudoku",
    name: "Sudoku Image Solver",
    languages: "Python, Keras, Pillow",
    description: "Sudoku Image Solver",
    date: "07-2021",
    info: (
      <>
        <p>
          Created a python program that uses{" "}
          <span class="font-bold">Tensorflow</span> and{" "}
          <span class="font-bold">PIL</span> to transform an image of a sudoku
          puzzle to a grid. Also created a model that reads different fonts and
          orientations of numbers. Used python backtracking to solve the sudoku
          puzzle.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/zhangj23/SudokuSavant">here</a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "insync",
    img: "insync.png",
    path: "/insync",
    name: "Social Platform",
    languages: "PHP, Firebase, SQL",
    description: "Automated Student Connection Platform",
    date: "12-2024",
    info: (
      <>
        <p>
          Insync is a dynamic web platform built with{" "}
          <span class="font-bold">PHP, MySQL, Firebase, GSAP</span>, and the{" "}
          <span class="font-bold">FullCalendar API</span>, designed to connect
          new incoming students. It features a{" "}
          <span class="font-bold">Firebase-powered real-time chat</span>
          system, a custom <span class="font-bold">matching algorithm</span> to
          pair students, and a tag-based recommendation system for clubs. The
          platform also includes{" "}
          <span class="font-bold">login functionality</span>, a full calendar
          for event management, and a dedicated{" "}
          <span class="font-bold">admin interface</span> for efficient
          moderation and control.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/zhangj23/insync">here</a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "pal",
    img: "palwebsite.png",
    path: "/pal",
    name: "Official Club Website",
    languages: "MERN, Imgur API",
    description: "Philippine American Club Website",
    date: "11-2024",
    info: (
      <>
        <p>
          The Philippine American League (PAL) website was developed using the{" "}
          <span class="font-bold">MERN</span> stack (MongoDB, Express, React,
          Node.js) as part of a club initiative. The platform features a
          responsive, <span class="font-bold">animated</span> front-end design
          to enhance user engagement, while the back-end allows administrators
          to seamlessly add events and upload images to the{" "}
          <span class="font-bold">cloud</span>.
        </p>
        <p>
          View the website{" "}
          <span class="underline">
            <a href="https://www.rpipal.com">here</a>
          </span>{" "}
          and the source code{" "}
          <span class="underline">
            <a href="https://github.com/RPIPAL/palwebsite">here</a>
          </span>
        </p>
        <p>
          (The backend is run on render so some service might need to startup of
          up to 50 seconds)
        </p>
      </>
    ),
  },
  {
    id: "easygo",
    img: "easygo.png",
    path: "/easygo",
    name: "Accessibility Mapper",
    languages: "MERN, GraphHopper API",
    description: "MERN stack Accessibility Mapper",
    date: "11-2024",
    info: (
      <>
        <p>
          EasyGo is a web application developed during a hackathon using{" "}
          <span class="font-bold">React, Node.js, Express, MongoDB</span>, and
          the <span class="font-bold">GraphHopper API</span>, designed to
          generate optimized, accessibility-focused routes. The application
          leverages <span class="font-bold">Leaflet</span> for interactive map
          rendering and implements <span class="font-bold">JWT</span>-based
          authentication to ensure secure access, offering a seamless and
          inclusive navigation experience.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/zhangj23/easygo">here</a>
          </span>
        </p>
      </>
    ),
  },
  {
    id: "club",
    img: "club.jpg",
    path: "/club",
    name: "SQL Login System: ClubHub",
    languages: "PHP, mySQL, Azure",
    description: "Full Stack Developer, ClubHub",
    date: "04-2024",
    info: (
      <>
        <p>
          Created a <span class="font-bold">full-stack</span> club planning
          platform with both admin and user login functionality. Used
          <span class="font-bold"> PHP</span> to build the functionality of the
          website and <span class="font-bold">mySQL</span> to store and pull
          login and club data. Specialized in web security using hashing,
          unauthorized redirect, and error handling.
        </p>
      </>
    ),
  },

  {
    id: "course",
    img: "course.jpg",
    path: "/course-selection",
    name: "Course Selection Website",
    languages: "Vue3, Django, GraphQL",
    description: "Backend Developer, Course Selection",
    date: "06-2023",
    info: (
      <>
        <p>
          Pioneered a new <span class="font-bold">full-stack</span> course
          selection platform for the school to be used by 1300+ students
          annually. Worked with <span class="font-bold">Django</span> as the
          main framework while using <span class="font-bold">GraphQL</span> and{" "}
          <span class="font-bold">Postgres</span> to store user and school data.
          Optimized server costs using{" "}
          <span class="font-bold">dataloaders</span> and created front-end
          animations.
        </p>
      </>
    ),
  },
  {
    id: "tictactoe",
    img: "tictactoe.jpg",
    path: "/tictactoe",
    name: "Minimax: TicTacToe Optimized",
    languages: "Python, Pygame",
    description: "Minimax: TicTacToe Optimized",
    date: "07-2021",
    info: (
      <>
        <p>
          Created a Tic Tac Toe Player AI using the minimax algorithm. Uses the
          most optimal strategy in order to either win or draw each game. Pygame
          section was used from Harvard's class template.
        </p>
        <p>
          View the source code{" "}
          <span class="underline">
            <a href="https://github.com/zhangj23/TicTacToe">here</a>
          </span>
        </p>
      </>
    ),
  },
];
